.full-page {
    height: 100vh;
    overflow-y: scroll;
    min-width: 800px;
}

h1 {
    text-align: center;
    color: white;
}

.header-area {
    display: block;
    justify-content: center;
    padding-bottom: 20px;
    .change-puzzle {
        text-align: center;
    }
    button {
        margin-left: 10px;
    }
}

#game-board {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.letter-box {
    //border: 2px solid gray;
    border-radius: 3px;
    margin: 2px;
    font-size: 1.5rem;
    font-weight: 700;
    height: 5rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;

    .letter-area {
        //border-bottom: 2px solid gray;
        border-radius: 3px;
        margin: 2px;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }
    .symbol-area {
        border-top: 2px solid white;
        //border-radius: 3px;
        margin: 2px;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

}

.filler-letter-box {
    //border: 2px solid gray;
    border-radius: 3px;
    margin: 2px;
    font-size: 1.5rem;
    font-weight: 700;
    height: 5rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;

    .filler-letter-area {
        border-bottom: 2px solid transparent;
        //border-radius: 3px;
        margin: 2px;
        font-size: 1.5rem;
        font-weight: 700;
        height: 2rem;
        width: 2rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }
    .filler-symbol-area {
        //border: 2px solid gray;
        border-radius: 3px;
        margin: 2px;
        font-size: 1.5rem;
        font-weight: 700;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

}

.selected {
    background-color: rgb(16, 73, 109);
}

.similar {
    background-color: rgb(63, 81, 92);
}

.correct {
    background-color: rgb(8, 197, 8);
}

.incorrect {
    background-color: rgb(255, 56, 56);
}

.hinted {
    background-color: rgba(255, 255, 0, 0.637);
}

.space-box {
    margin: 2px;
    font-size: 2.5rem;
    font-weight: 700;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.letter-row {
    display: flex;
}

#keyboard-cont {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#keyboard-cont div {
    display: flex;
}

.second-row {
    margin: 0.5rem 0;
}

.third-row {
    margin-bottom: 0.5rem;
}

.keyboard-button {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem;
    margin: 0 2px;
    cursor: pointer;
    text-transform: uppercase;
}