.word-image {
    position: absolute;
    top: 10%;
    right: 10%;
    bottom: auto;
    left: auto;
    margin: auto;

    img {
        width: 350px;
        height: auto;
        opacity: .7;
    }
}

.scroll {
    background-image: linear-gradient(#2b2b3e, #191924);
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding: 20px;
}

h2 {
    color: #247ba0;
}

h4 {
    color: #a04924;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a04924;
  }