.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    svg {
      width: 100%;
      height: auto;
      bottom: 0;
      transform: rotateZ(-10deg) !important;

      .path {
        stroke-dasharray: 800;
        stroke-dashoffset: 800;
        animation: dash 6s linear forwards;
      }
      
      @keyframes dash {
        from {
          stroke-dashoffset: 1000;
        }
        to {
          stroke-dashoffset: 0;
        }
      }

    }
  }
  
  
  